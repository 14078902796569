import React from "react";
import { connect } from "react-redux";
import { setDate } from "../../../actions/Database/Pointage";
import { DateHandler } from "../../../helper";
import { checkIfDateIsInAllowed, incrementDate } from "../../../helper/date";

const DatePickerCal = ({
  Pointage,
  lastSync,
  SessionData,
  dispatch,
  isAllowed,
}) => {
  return (
    <>
      {[3, 4, 7, 1, 2, 5, 9, 50].some((value) =>
        Pointage.prestationsType.includes(value)
      ) && (
        <div className="dateSelector">
          <button
            className="btn btn--primary"
            disabled={!isAllowed(incrementDate(new Date(Pointage.date), -1))}
            onClick={() => {
              dispatch(
                setDate({ date: incrementDate(new Date(Pointage.date), -1) })
              );
            }}
          >
            &#8592;
          </button>
          <input
            className=""
            type="date"
            id="start"
            name="Pointage date"
            value={DateHandler.FormatDate(new Date(Pointage.date))}
            onChange={(event) =>
              dispatch(setDate({ date: event.target.value }))
            }
            min={DateHandler.FormatDate(
              DateHandler.IndentDate(
                lastSync ? new Date(lastSync) : new Date(),
                0 - SessionData.RootPeriodBefore
              )
            )}
            max={DateHandler.FormatDate(
              DateHandler.IndentDate(
                lastSync ? new Date(lastSync) : new Date(),
                0 + SessionData.RootPeriodAfter
              )
            )}
          />
          <button
            className="btn btn--primary"
            disabled={!isAllowed(incrementDate(new Date(Pointage.date), 1))}
            onClick={() => {
              dispatch(
                setDate({ date: incrementDate(new Date(Pointage.date), 1) })
              );
            }}
          >
            &#8594;
          </button>
        </div>
      )}
    </>
  );
};

export default connect(({ Pointage, Auth, Synchro }) => ({
  SessionData: Auth.SessionData,
  lastSync: Synchro.lastSync,
  Pointage,
  isAllowed: checkIfDateIsInAllowed(Auth, Synchro),
}))(DatePickerCal);
