import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setPrestationsId,
  getPrestation,
  setMoment,
  setDate,
} from "../../../actions/Database/Pointage";
import { SessionHandler } from "../../../helper";
import DatePickerCal from "./DatePickerCal";
import SelectMultiDate from "./SelectMultiDate";
import SelectSingleDate from "./SelectSingleDate";
const ArianePointage = ({
  where,
  history,
  Pointage,
  dispatch,
  SessionData,
}) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(Object.values(SessionHandler.prestationsParser())
    .filter(
      (presta) =>
        presta.homeIcon &&
        SessionData.PrestationsMap.some((prestaMap) =>
          prestaMap.prestation_types.some((id) =>
            presta.id.includes(id)
          )
        ) &&
        Object.values(SessionHandler.getPrestations()).some((prestas) =>
          presta.id.includes(prestas)
        )
    ));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (services.length === 1) {
      dispatch(setPrestationsId({ location: services[0].url }))
    }
    // eslint-disable-next-line
  }, [services]);
  const sortedServices = services.sort((a, b) => a.order - b.order);
  return (
    <>
      {where !== undefined && (
        <>
          <select
            className=""
            name="PointageItem"
            onChange={(event) => {
              history.push(`/pointage/${event.target.value}`);
              dispatch(setDate({ date: new Date() }));
              dispatch(setPrestationsId({ location: event.target.value })).then(
                () => dispatch(getPrestation())
              );
            }}
            value={where}
            disabled={sortedServices.length === 1}
          >
            {sortedServices.map((value) => (
                <option value={value.url} key={value.url}>
                  {value.Label}
                </option>
              ))}
          </select>
          <DatePickerCal />
          <SelectSingleDate />
          <SelectMultiDate />
          {!Pointage.closed  && !Pointage.prestationsType.includes(5, 50) && (
            <select
              className=""
              name="Moment"
              value={Pointage.momentID}
              onChange={(event) =>
                dispatch(setMoment({ momentId: event.target.value }))
              }
            >
              <option disabled={true} key={-1} value={-1}>
                Moments
              </option>
              {Object.values(Pointage.moment).map((prestation) => (
                <option key={prestation.id} value={prestation.id}>
                  {prestation.code}
                </option>
              ))}
            </select>
          )}
        </>
      )}
    </>
  );
};
export default connect(({ Auth, Synchro, Pointage }) => ({
  Pointage,
  SessionData: Auth.SessionData,
}))(ArianePointage);
