import { filter } from "./filterDefinition";
import { getLocationTag } from "./useLocationId";
import { prestationsParser, prestationTypeParser } from "./user";

const getFilterLocalName = (Name) => `LocalFilter2${Name}`;

export const getFilterLocal = (Name) =>
  JSON.parse(localStorage.getItem(getFilterLocalName(Name)));
export const setFilterLocal = (Name, content) =>
  localStorage.setItem(getFilterLocalName(Name), JSON.stringify(content));

export const getFilterName = (Prestations) => {
  var tag = getLocationTag(document.location);
  if (tag === prestationsParser()[1].url)
    return Prestations.some((presta) => !presta.secondary && presta.type === 2)
      ? prestationTypeParser()[2]
      : prestationsParser()[1].url;

  if (tag === prestationsParser()[2].url)
    return Prestations.some((presta) => !presta.secondary && presta.type === 4)
      ? prestationTypeParser()[4]
      : prestationsParser()[2].url;

  return tag;
};

export const checkFilterSanity = (
  Level,
  School,
  Group,
  Bus,
  Prestations,
  teacher,
  Stop,
  Service,
  Location
) => {
  var ToFilter = {
    Groupe: Group,
    School: School,
    Teacher: teacher,
    Level: Level,
    Bus: Bus,
    Presta: Prestations,
    Stop: Stop,
    Service: Service,
    Location: Location,
  };
  [
    { tag: prestationTypeParser()[2] },
    { tag: prestationTypeParser()[4] },
    { tag: prestationTypeParser()[5] },
    { tag: prestationsParser()[1].url },
    { tag: prestationsParser()[2].url },
    { tag: prestationsParser()[3].url },
    { tag: prestationsParser()[4].url },
    { tag: prestationsParser()[5].url },
    { tag: prestationsParser()[6].url },
    { tag: prestationsParser()[7].url },
    { tag: prestationsParser()[8].url },
    { tag: prestationsParser()[50].url },
  ]
    .map(({ tag }) => ({
      value: getFilterLocal(tag),
      tag,
    }))
    .forEach(({ value, tag }) => {
      if (value === undefined || value === null) return;
      var filteredArray = filter();
      Object.entries(value).forEach(([key, categorie]) => {
        if (
          ["active", "Age", "BirthYear", "Special"].some((val) => val === key)
        )
          return;
        Object.entries(categorie).forEach(([momentId, content]) => {
          // Transformation du tableau des filtres en tableau d'id
          let newArrayFilter = [];
          if (ToFilter[key][0] === undefined) {
            // Si les éléments du tableau sont des objets
            Object.entries(ToFilter[key]).forEach(([keyFilter, contentFilter]) => {
              newArrayFilter.push(contentFilter?.id || contentFilter);
            })
          } else {
            // Si c'est un tableau classique
            newArrayFilter = ToFilter[key].map(val => val?.id || val);
          }
          filteredArray[key][momentId].filter = filterArray(
            content.filter,
            // ToFilter[key].map((val) => val?.id || val)
            newArrayFilter
          );
          filteredArray[key][momentId].enabled =
            filteredArray[key][momentId].filter.length > 0;
        });
      });
      Object.entries(filteredArray.active).forEach(([momentId, oldValue]) => {
        filteredArray.active[momentId] = Object.keys(filteredArray).some(
          (key) => {
            if (
              ["active", "Age", "BirthYear", "Special"].some(
                (val) => val === key
              )
            )
              return false;
            return filteredArray[key][momentId].enabled;
          }
        );
      });
      setFilterLocal(tag, filteredArray);
    });
};

export const filterArray = (arrayToTest, arrayWhoContain) =>
  arrayToTest.filter((value) => arrayWhoContain.includes(value));
