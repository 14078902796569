import { dateIsBetweenOrEqualDate, isBetweenNumber } from "../helper/date";
import { getChildAge } from "./dbChild";
import { dateStringToDate } from "./date";
import { cleanString, findPrestations } from "./index";
// import { getLocationID } from "./useLocationId";
import { Operator } from "./filterDefinition";
import { getStops } from "../actions/Database/Pointage";

export const getAvailablePrestations = (
  prestations,
  momentId,
  prestationsType
) => {
  return prestations
    .filter((Prestations) => {
      return momentId === 1
        ? true
        : Number(Prestations.moment) === Number(momentId);
    })
    .filter((presta) => {
      if (
        prestationsType &&
        [10, 8, 6].some((type) => prestationsType.includes(type))
      ) {
        return (
          String(document.location.pathname.split("/")[3]) === String(presta.id)
        );
      }
      return true;
    });
};

export const filterPrestation = (
  { secondary, filters, type, service_type, id, possible_days },
  child,
  showSecondary = false,
  Signups,
  date,
  holidays
) => {
  if (
    (showSecondary === false && secondary === true) ||
    // ([6, 8, 10].some((tag) => getLocationID(document.location).includes(tag)) &&
    //   String(document.location.pathname.split("/")[3]) !== String(id)) ||
    !possible_days.some(day => day.toString() === new Date(date).getDay().toString())
  ) {
    return false;
  }
  var signup = child.signups.find(findSignup(Signups, date));
  return filterByPrestaFilter(
    child,
    signup,
    date,
    filters,
    { type, service_type },
    holidays,
    secondary
  );
};

var filterByPrestaFilter = (
  child,
  signup,
  date,
  filters,
  { type, service_type },
  holidays,
  secondary,
  dateId,
) => {
  var affectation = selectAffectation(
    signup.affectations,
    { type, service_type },
    date,
    holidays
  );
  if (affectation === undefined) return false;
  if (
    filterChildByLocations(filters.locations, affectation) ||
    filterChildBySchools(filters.schools, signup) ||
    filterChildByLevels(filters.levels, signup) ||
    filterChildByGroups(filters.groups, affectation) ||
    filterChildByAge(filters.age, child) ||
    filterChildByBirthYear(filters.birth_year, child) ||
    filterChildByHolidays(filters.holidays, date, holidays) ||
    filterChildByBus(filters.bus, affectation)
  )
    return false;
  return true;
};

export const selectAffectation = (
  childAffectation,
  { type, service_type },
  date,
  holidays
) => {
  if (type === 8) return false;
  if (type === 3 || type === 6 || (type === 7 && [3, 4].includes(service_type)))
    return childAffectation.find(
      (affectation) =>
        affectation.type ===
          holidays.find((holiday) =>
            dateIsBetweenOrEqualDate(
              new Date(date),
              dateStringToDate(holiday.start_date),
              dateStringToDate(holiday.end_date)
            )
          )?.service_type || false
    );
  var dayId = new Date(date).getDay();
  if (
    type === 1 ||
    type === 7 ||
    type === 9 ||
    type === 5 ||
    type === 10 ||
    type === 8 ||
    type === 6 ||
    type === 50 ||
    (type === 2 && dayId === 3) ||
    (type === 4 && dayId === 6)
  )
    return childAffectation.find(
      (affectation) =>
        affectation.type ===
        (type === 7 ? service_type : type === 50 ? 5 : type === 4 ? 8 : type === 5 ? 7 : type)
    );
};

export var findSignup = (Signups, date) => (value) =>
  value.year_id ===
  Signups.find((signupG) =>
    dateIsBetweenOrEqualDate(
      new Date(date),
      dateStringToDate(signupG.start_date),
      dateStringToDate(signupG.end_date)
    )
  )?.id;

export const userFilter = (
  child,
  filtre,
  Signups,
  date,
  Prestation,
  Reservation,
  holidays,
  moment
) => {
  var signup = child.signups.find(findSignup(Signups, date));
  var affect = Prestation.reduce((old, presta) => {
    var affectation = selectAffectation(
      signup.affectations,
      presta,
      date,
      holidays
    );

    if ((old.findIndex((affectaOld) => affectaOld.id === affectation.id) === -1) && affectation !== undefined)
      old.push(affectation);
    return old;
  }, []);

  return !(
    userFilterChildByAge(child, filtre.Age[moment]) ||
    userFilterChildByBirthYear(child, filtre.BirthYear[moment]) ||
    userFilterChildByGroup(affect, signup, filtre.Groupe[moment]) ||
    (filtre.Service && userFilterChildByService(affect, filtre.Service[moment])) ||
    userFilterChildByLocation(affect, filtre.Location[moment]) ||
    userFilterChildByLevel(signup, filtre.Level[moment]) ||
    userFilterChildBySchool(signup, filtre.School[moment]) ||
    userFilterChildByTeacher(signup, filtre.Teacher[moment]) ||
    userFiltreChildByBus(
      affect,
      filtre.Bus[moment],
      Reservation.filter((resa) => resa.child_id === child.id),
      Prestation
    ) ||
    userFiltreChildByStop(
      affect,
      filtre.Stop[moment],
    ) ||
    userFilterChildSpecial(
      Reservation.filter(
        (resa) => resa.child_id === child.id && !resa.secondary
      )[0],
      filtre.Special[moment]
    ) ||
    userFilterChildByPresta(
      Reservation.filter(
        (resa) => resa.child_id === child.id
      ),
      filtre.Presta[moment]
    )
  );
};

export const filterChildSearch = (search) => (child) => {
  if (
    search !== "" &&
    !(
      cleanString(child.first_name).includes(cleanString(search)) ||
      cleanString(child.last_name).includes(cleanString(search))
    )
  )
    return false;
  return true;
};

export const filterChild = (
  child,
  showAllChild,
  availablePrestation,
  Reservation,
  search,
  Signups,
  date,
  holidays,
) => {
  if (!filterChildSearch(search)(child)) return false;
  if (
    Reservation.some(
      (resa) =>
        !findPrestations(availablePrestation, resa)?.secondary &&
        resa.child_id === child.id
    )
  ) {
    return true;
  }
  var signup = child.signups.find(findSignup(Signups, date));
  if (signup === undefined) return false;
  if (!showAllChild) return false;
  return availablePrestation.reduce(
    (retour, { filters, secondary, type, service_type }) => {
      if (retour === true) return true;
      if (secondary === true) return false;
      return filterByPrestaFilter(
        child,
        signup,
        date,
        filters,
        { type, service_type },
        holidays,
      );
    },
    false
  );
};

export const userFilterChildByPresta = (reservation, Presta) => {
  if (Presta.enabled !== true) return false;
  return !reservation.some((element) => {
    return Presta.filter.includes(element.prestation_id)
  });
};

export const userFilterChildByLocation = (affectations, Location) => {
  return Location.enabled === true &&
  !(affectations.find(affect => Location.filter.includes(affect.location_id)) !== undefined)};

export const userFilterChildSpecial = (reservation, special) => {
  if (special.enabled !== true) return false;
  if (reservation === undefined && (!special.filter.includes(1) || special.filter.length === 0)) return true;

  // Renvoyer uniquement les pointages manquants
  if (
    special.filter.includes(3) &&
    (reservation.is_present === null ||
    (reservation.is_present === 1 &&
      (reservation.arrived_at === null ||
        reservation.arrived_at === "" ||
        reservation.arrived_at === undefined ||
        reservation.departed_at === null ||
        reservation.departed_at === "" ||
        reservation.departed_at === undefined)))
  )
    return false;
  // Renvoyer uniquement les présences
  if (
    special.filter.includes(4) &&
    reservation.is_present === 1
  ) {
    return false;
  }
  // Renvoyer uniquement les absents
  if (
    special.filter.includes(5) &&
    reservation.is_present === 0
  ) {
    return false;
  }
  // Renvoyer tout les enfants
  if (special.filter.includes(1)) {
    return false;
  }

  if (special.filter.includes(2)) {
    return false;
  }

  return true;
};

export const userFilterChildByGroup = (affectations, signup, group) => {
  return group.enabled === true &&
  !(affectations.find(affect => group.filter.includes(affect.group_id)) !== undefined)};

export const userFilterChildByService = (affectations, service) => {
  return service.enabled === true &&
  !(affectations.find(affect => service.filter.includes(affect.mealservice_id)) !== undefined)};

export const userFilterChildBySchool = (signup, School) =>
  School.enabled === true &&
  !School.filter.includes(signup.school_id);

export const userFilterChildByTeacher = (signup, Teacher) =>
  Teacher.enabled === true &&
  !Teacher.filter.includes(signup.teacher_id);

export const userFilterChildByLevel = (signup, Level) =>
  Level.enabled === true && !Level.filter.includes(signup.level);

export const userFilterChildByAge = (child, Age) =>
  Age.enabled === true &&
  filterByOperator(Age.value, getChildAge(child.birth_date), Age.operator);

export const userFilterChildByBirthYear = (child, Year) =>
  Year.enabled === true &&
  filterByOperator(
    Year.value,
    new Date(child.birth_date).getFullYear(),
    Year.operator
  );

/*const secondaryAndABus = (prestation, resa) =>
  prestation.secondary === true && prestation.secondary_type === 2;*/

export const userFiltreChildByBus = (
  affectations,
  { enabled, filter },
) => {
    if (enabled === true) {
      // Sans bus
      if (filter.includes(-1)) {
        return (affectations.find(affect => affect.bus_arriving_route_id !== null) !== undefined ||
        affectations.find(affect => affect.bus_departing_route_id !== null) !== undefined);
      }

      // Par bus
      return !(
        (affectations.find(affect => filter.includes(affect.bus_arriving_route_id)) !== undefined ||
        affectations.find(affect => filter.includes(affect.bus_departing_route_id)) !== undefined)
      );
    }
  return false;
};

export const userFiltreChildByStop = (
  affectations,
  {enabled, filter},
) => {
    if (enabled === true) {
      if((affectations.find(affect => filter.includes(affect.bus_arriving_stop_id)) !== undefined ||
        affectations.find(affect => filter.includes(affect.bus_departing_stop_id)) !== undefined)){
        return false;
      }
      return true;
    }
    return false;
}

const filterByOperator = (from, than, idOperator) =>
  !Operator[idOperator].action(from, than);

export const filterChildByLocations = (locations, affectations) => {
  if (!locations.enabled) {
    return false; // Si le filtre par lieu n'est pas activé, on ne retire pas la prestation
  }

  // Si le lieu est exclu, alors on retire la prestation
  if (locations.excluded.includes(affectations.location_id)) {
    return true;
  }

  // Il n'y a aucun filtre de lieu inclus (donc on filtre pas)
  if (locations.possible.length === 0) {
    return false;
  }

  // Si le lieu est inclus, alors on garde la prestation (sinon on la retire)
  if (locations.possible.includes(affectations.location_id)) {
    return false;
  }
  return true;
}

export const filterChildBySchools = (schools, signup) => {
  if (!schools.enabled) {
    return false; // Si le filtre par école n'est pas activé, on ne retire pas la prestation
  }

  // Si l'école est exclue, alors on retire la prestation
  if (schools.excluded.includes(signup.school_id)) {
    return true;
  }

  // Il n'y a aucun filtre d'école inclus (donc on filtre pas)
  if (schools.possible.length === 0) {
    return false;
  }

  // Si l'école est incluse, alors on garde la prestation (sinon on la retire)
  if (schools.possible.includes(signup.school_id)) {
    return false;
  }
  return true;
}

export const filterChildByLevels = (levels, signup) => {
  if (!levels.enabled) {
    return false; // Si le filtre par niveau n'est pas activé, on ne retire pas la prestation
  }

  // Si le niveau est exclu, alors on retire la prestation
  if (levels.excluded.includes(signup.level)) {
    return true;
  }

  // Il n'y a aucun filtre de niveau inclus (donc on filtre pas)
  if (levels.possible.length === 0) {
    return false;
  }

  // Si le niveau est inclus, alors on garde la prestation (sinon on la retire)
  if (levels.possible.includes(signup.level)) {
    return false;
  }
  return true;
}

export const filterChildByGroups = (groups, affectations) => {
  if (!groups.enabled) {
    return false; // Si le filtre par groupe n'est pas activé, on ne retire pas la prestation
  }

  // Si le groupe est exclu, alors on retire la prestation
  if (groups.excluded.includes(affectations.group_id)) {
    return true;
  }

  // Il n'y a aucun filtre de groupe inclus (donc on filtre pas)
  if (groups.possible.length === 0) {
    return false;
  }

  // Si le groupe est inclus, alors on garde la prestation (sinon on la retire)
  if (groups.possible.includes(affectations.group_id)) {
    return false;
  }
  return true;
}

export const filterChildByServices = (services, affectations) =>
  services.enabled === true && !services.possible.includes(affectations.smealservice_id);

export const filterChildByAge = (age, child) =>
  age.enabled === true &&
  !isBetweenNumber(age.min, age.max, getChildAge(child.birth_date));

export const filterChildByBirthYear = (birth_year, child) =>
  birth_year.enabled === true &&
  !isBetweenNumber(
    birth_year.min,
    birth_year.max,
    new Date(child.birth_date).getFullYear()
  );

export const filterChildByHolidays = (holidays, date, SchoolHolidays) => {
  const holiday = SchoolHolidays.find((holiday) =>
    dateIsBetweenOrEqualDate(
      new Date(date),
      dateStringToDate(holiday.start_date),
      dateStringToDate(holiday.end_date)
    )
  );
  return holidays.enabled && holiday && !holidays.possible.includes(holiday.type);
};

export const filterChildByBus = (bus, affectation) => {
  if (!bus.enabled) {
    return false; // Si le filtre par bus n'est pas activé, on ne retire pas la prestation
  }

  // Si le bus est exclu, alors on retire la prestation
  if (bus.excluded.includes(affectation.bus_departing_route_id) || bus.excluded.includes(affectation.bus_arriving_route_id)) {
    return true;
  }

  // Il n'y a aucun filtre de bus inclus (donc on filtre pas)
  if (bus.possible.length === 0) {
    return false;
  }

  // Si le bus est inclus, alors on garde la prestation (sinon on la retire)
  if (bus.possible.includes(affectation.bus_departing_route_id) || bus.possible.includes(affectation.bus_arriving_route_id)) {
    return false;
  }
  return true;
}

  export const getFilterChild = (Pointage, Child) => {
    var signup =
      Pointage.Signup &&
      Pointage.Signup.find((Signup) =>
      dateIsBetweenOrEqualDate(
          new Date(Pointage.date),
          dateStringToDate(Signup.start_date),
          dateStringToDate(Signup.end_date)
        )
      );
    var swapFilter = Pointage.swapFilter;
    var moment = Pointage.momentID;
    var res = [];
    if (signup !== undefined && moment !== -1) {
      // Filtre par Bus
      if (swapFilter.Bus[moment].filter.includes(-1)) {
        res.push({id: -1, name: "Sans bus", category: "Bus"})
      }
      if (Pointage.Bus !== undefined && Pointage.Bus.length > 0) {
        Pointage.Bus.forEach((bus) => {
          if (swapFilter.Bus[moment].filter.includes(bus.id)) {
            res.push({id: bus.id, name: bus.name, category: "Bus"})
          }
        })
      }
      // Filtre par Arrêts de bus
      var stops = getStops(Pointage);
      if (stops.length > 0) {
        stops.forEach((stop) => {
          if (swapFilter.Stop[moment].filter.includes(stop.id)) {
            res.push({id: stop.id, name: stop.name, category: "Stop"})
          }
        })
      }
      // Filtre par Groupe
      if (Child.childGroup !== undefined && Child.childGroup.length > 0) {
        Child.childGroup.forEach((group) => {
          if (swapFilter.Groupe[moment].filter.includes(group.id)) {
            res.push({id: group.id, name: group.name, category: "Groupe"})
          }
        })
      }
      // Filtre par Service
      if (Child.childMealService !== undefined && Child.childMealService.length > 0 && swapFilter.Service) {
        Child.childMealService.forEach((service) => {
          if (swapFilter.Service[moment].filter.includes(service.id)) {
            res.push({id: service.id, name: service.name, category: "Service"})
          }
        })
      }
      // Filtre par lieu d'accueil
      if (signup.locations !== undefined) {
        signup.locations.forEach((location) => {
          if (swapFilter.Location !== undefined  && swapFilter.Location[moment].filter.includes(location.id)) {
            res.push({id: location.id, name: location.name, category: "Location"});
          }
        })
      }
      // Filtre par Niveau
      if (signup.levels !== undefined) {
        for(const [levname, levid] of Object.entries(signup.levels)) {
          if (swapFilter.Level[moment].filter.includes(levid)) {
            res.push({id: levid, name: levname, category: "Level"});
          }
        }
      }
      // Filtre par Presta
      if (Pointage.Prestations !== undefined && Pointage.Prestations.length > 0) {
        Pointage.Prestations.forEach((presta) => {
          if (swapFilter.Presta[moment].filter.includes(presta.id)) {
            res.push({id: presta.id, name: presta.name, category: "Presta"});
          }
        })
      }
      // Filtre par School
      if (signup.schools !== undefined && signup.schools.length > 0) {
        signup.schools.forEach((school) => {
          if (swapFilter.School[moment].filter.includes(school.id)) {
            res.push({id: school.id, name: school.name, category: "School"});
          }
        })
        // Filtre par Enseignant
        if (signup.teachers !== undefined && signup.teachers.length > 0) {
          signup.teachers.forEach((teacher) => {
            if (swapFilter.Teacher[moment].filter.includes(teacher.id)) {
              res.push({id: teacher.id, name: teacher.name, category: "Teacher"});
            }
          })
        }
      }
      // Filtre par Spécial
      if (swapFilter.Special[moment].filter.includes(1)) {
        res.push({id: 1, name: "Tous les enfants", category: "Special"});
      }
      if (swapFilter.Special[moment].filter.includes(2) || swapFilter.Special[moment].enabled === false) {
        res.push({id: 2, name: "Toutes les réservations", category: "Special"});
      }
      if (swapFilter.Special[moment].filter.includes(3)) {
        res.push({id: 3, name: "Pointages manquants", category: "Special"});
      }
      if (swapFilter.Special[moment].filter.includes(4)) {
        res.push({id: 4, name: "Enfants présents", category: "Special"});
      }
      if (swapFilter.Special[moment].filter.includes(5)) {
        res.push({id: 5, name: "Enfants absents", category: "Special"});
      }
      if (res.length > 0) {
        return res;
      }
      return [{name: "Aucun filtre actif"}];
    }

    return [{name: 'Aucun filtre actif'}];
  }
